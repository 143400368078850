<!-- 团购管理页面 -->
<template>
  <div>团购管理页面</div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  methods: {}
}

</script>
<style lang='scss' scoped>
</style>